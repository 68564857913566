<table class="table" *ngIf="standings">
	<thead>
		<tr>
			<th scope="col" class="position">#</th>
			<th scope="col" class="start-number">Number</th>
			<th scope="col">Driver</th>
			<th scope="col">Laps</th>
			<th scope="col">Last lap</th>
<!--			<th scope="col" class="season-points">Points</th>-->
		</tr>
	</thead>
	<tbody>
    <ng-container *ngFor="let standing of standings; trackBy: trackStanding">
      <tr class="primary-row" (click)="rowDetailsOpen[standing.driver.startNumber] = !rowDetailsOpen[standing.driver.startNumber]">
        <td scope="row" class="position">{{standing.position}}.</td>
        <th class="start-number" title="RFIDs: {{standing.driver.epcs.join(', ')}}">
          {{standing.driver.startNumber}}
        </th>
        <td class="driver-name">
          {{standing.driver.name}}
          <small>{{standing.driver.model}}</small>
        </td>
        <td class="average" [class.complete]="standing.rankingResults?.length >= resultsConfig.rankingLapsNumber">
          {{standing.totalResults}}
        </td>
        <td class="ranking-results">
          <span *ngFor="let result of standing.rankingResults; trackBy: trackResult" class="result-wrapper">
            <mc-result
              [result]="result"
              (changed)="recalculateOrder()"
              (click)="$event.stopPropagation(); rowDetailsOpen[standing.driver.startNumber] = true"
            ></mc-result>
          </span>
        </td>
<!--        <td class="season-points">-->
<!--          {{ standing.seasonPoints }}-->
<!--          <i class="fa fa-trophy best-lap-icon" *ngIf="standing.isBastLap" title="Best lap in category"></i>-->
<!--        </td>-->
      </tr>
      <tr *ngIf="rowDetailsOpen[standing.driver.startNumber]" class="details-row">
        <td colspan="2"></td>
        <th colspan="2">
          <div
            class="laptime-bar"
            *ngFor="let result of standing.results; trackBy: trackResult"
            [class.invalid]="!(result.microtime | laptimeRangeFilter:resultsConfig)"
            [class.hidden]="!isModerable && !(result.microtime | laptimeRangeFilter:hardLimitResultsConfig)"
            [class.rejected]="result.verification === false"
            [class.restored]="result.verification === true"
          >
            <i
              class="verification-button fa fa-wrench"
              *ngIf="isModerable"
              (click)="toggleVerification(result)"
            ></i>
            <span
              [style.width]="(result.microtime / (resultsConfig.maximumLapSeconds * 1000 * 1000) * 98) + '%'"
            ></span>
            <div class="result-details" *ngIf="isModerable" title="Start time (start EPC) - finish time (finish EPC)">
              {{ (result.createdAt - result.microtime / 1000) | date:'HH:mm:ss' }} ({{ result.startEpc }})
              -
              {{ result.createdAt | date:'HH:mm:ss' }} ({{ result.finishEpc }})
            </div>
          </div>
        </th>
        <td colspan="2">
          <div
            *ngFor="let result of standing.results; trackBy: trackResult"
            class="result-wrapper"
            [class.out-of-range]="!(result.microtime | laptimeRangeFilter:resultsConfig)"
            [class.hidden]="!isModerable && !(result.microtime | laptimeRangeFilter:hardLimitResultsConfig)"
            [class.rejected]="result.verification === false"
            [class.restored]="result.verification === true"
          >
            <mc-result
              [result]="result"
              (changed)="recalculateOrder()"
              (click)="$event.stopPropagation()"
            ></mc-result>
            <small> at {{ result.createdAt | date:'HH:mm:ss' }}</small>
            <div class="result-details" *ngIf="isModerable" title="Result ID">
              {{ result._id }}
            </div>
          </div>
        </td>
      </tr>
    </ng-container>
	</tbody>
</table>
