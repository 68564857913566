import { Injectable } from '@angular/core';
import { Standing } from '../standing';
import { GateReading } from '../../gepard/gate-readings/gate-reading';
import { ResultsService } from '../results/results.service';
import { Result } from '../results/result/result';
import { DriversService } from '../../gepard/drivers/drivers.service';
import { TagsService } from '../../gepard/tags/tags.service';
import { ResultsFilterPipe } from '../results/results-config/results-filter.pipe';
import { ResultsConfig } from '../results/results-config/results-config';
import { NormalizeLocalISOStringPipe } from '../../app-commons/pipes/normalize-timestamp.pipe';
import { SeasonPointsPipe } from '../../seasons/season-points.pipe';
import { ToUniqueResultIdPipe } from '../results/result/to-unique-result-id.pipe';

@Injectable({
  providedIn: 'root'
})
export class StandingsService {

  readonly RFID_ACCURACY_THRESHOLD = 2 * 1000 * 1000;

  standings: Standing[] = [];

  constructor(
    private driversService: DriversService,
    private tagsService: TagsService,
    private resultsFilter: ResultsFilterPipe,
    private normalizeILocalSOStringPipe: NormalizeLocalISOStringPipe,
    private seasonPointsFilterPipe: SeasonPointsPipe,
    private readonly toUniqueResultIdPipe: ToUniqueResultIdPipe
  ) {
  }

  findByEpc(epc: string): Standing {
    return this.standings.find(standing => standing.driver && standing.driver.epcs.includes(epc));
  }

  findByStartNumber(startNumber: number): Standing {
    return this.standings.find(standing => standing.driver && standing.driver.startNumber === startNumber);
  }

  fromResults(results: Result[]): Standing[] {
    const standingsByStartNumber = {};
    for (let i = 0; i < results.length; i++) {
      const result = results[i];
      const driver = this.driversService.findByStartNumber(result.driverId);
      if (driver) {
        if (!standingsByStartNumber[result.driverId]) {
          standingsByStartNumber[result.driverId] = {
            driver,
            results: []
          } as Standing;
        }
        standingsByStartNumber[result.driverId].results.push(result);
      }
    }
    return Object.values<Standing>(standingsByStartNumber).sort(this.compareStandingsByStartNumber);
  }

  getRecalculated(standing: Standing, resultsConfig: ResultsConfig): Standing {
    const rankingResults = this.extractRankingResults(standing.results, resultsConfig);
    return {
      ...standing,
      ... {
        rankingResults,
        rankingResultsAverage: this.average(rankingResults.map(result => result.microtime)),
        totalResults: standing.results.length
      }
    };
  }

  protected extractRankingResults(results: Result[], resultsConfig: ResultsConfig): Result[] {
    return this.resultsFilter.transform(results, resultsConfig)
      .sort((a, b) => b.createdAt - a.createdAt)
      .slice(0, 1);
  }

  protected average(results, n = 3) {
    let i;
    let sum = 0;
    for (i = 0; i < results.length && (n <= 0 || i < n); i++) {
      sum += results[i];
    }
    return i ? (sum / i) : null;
  }

  recalculateStandingsOrder(standings: Standing[]) {
    standings.sort((a, b) => {
      if (+a.totalResults === 0) {
        return -1;
      } else if (+b.totalResults === 0) {
        return 1;
      } else if (a.totalResults === b.totalResults) {
        if (+a.rankingResultsAverage === 0) {
          return 1;
        } else if (+b.rankingResultsAverage === 0) {
          return -1;
        } else {
          return a.rankingResultsAverage - b.rankingResultsAverage;
        }
      } else {
        return b.totalResults - a.totalResults;
      }
    });
    return standings;
  }

  recalculateStandingsPositions(standings: Standing[]) {
    standings.forEach((standing, i) => {
      standing.position = i + 1;
    });
    return standings;
  }

  recalculateStandingsSeasonPoints(standings: Standing[], pointsMultiplier?: number) {
    standings.forEach((standing, i) => {
      standing.seasonPoints = this.seasonPointsFilterPipe.transform(standing.position, pointsMultiplier, standing.isBastLap);
    });
    return standings;
  }

  recalculateStandingsBestLap(standings: Standing[]) {
    let bestLap: Result;
    standings.forEach((standing, i) => {
      standing.rankingResults.forEach(rankingResult => {
        // TODO Change to ">=" and make it an array of results (two different people can have best lap).
        if (!bestLap || bestLap.microtime > rankingResult.microtime) {
          bestLap = rankingResult;
        }
      });
    });
    if (bestLap) {
      standings.forEach((standing, i) => standing.isBastLap = standing.driver.startNumber === bestLap.driverId);
    }
    //   this.bestLapOfRanking$.next(bestLap);
    return standings;
  }

  // TODO Add "multipled tags" support!
  fromGateReadings(gateReadings: GateReading[], minLapSeconds?: number, maxLapSeconds?: number): Standing[] {
    const standingsByStartNumber = {};
    const lastLapStartsByStartNumber = {};
    const lapsCountsByStartNumber = {};
    const minLapDuration = (minLapSeconds || 80) * 1000 * 1000;
    const maxLapDuration = (maxLapSeconds || 300) * 1000 * 1000;

    for (let i = 0; i < gateReadings.length; i++) {
      const gateReading = gateReadings[i];
      const driver = this.driversService.findByEpc(gateReading.tag_code);
      if (driver) {
        if (!standingsByStartNumber[driver.startNumber]) {
          standingsByStartNumber[driver.startNumber] = {
            driver,
            results: []
          } as Standing;
        }

        const standing = standingsByStartNumber[driver.startNumber];

        if (driver.startNumber in lastLapStartsByStartNumber) {
          const startTimestamp = new Date(this.normalizeILocalSOStringPipe.transform(lastLapStartsByStartNumber[driver.startNumber].first_detection_date)).getTime();
          const finishTimestamp = new Date(this.normalizeILocalSOStringPipe.transform(gateReading.first_detection_date)).getTime();

          const diff = (finishTimestamp - startTimestamp) * 1000;
          if (diff <= this.RFID_ACCURACY_THRESHOLD) {
            // Ignores gate reading.
          } else {
            if (diff >= minLapDuration && diff <= maxLapDuration) {
              if (driver.startNumber in lapsCountsByStartNumber) {
                lapsCountsByStartNumber[driver.startNumber]++;
              } else {
                lapsCountsByStartNumber[driver.startNumber] = 0;
              }
              const _id = this.toUniqueResultIdPipe.transform(finishTimestamp, gateReading.tag_code);
              const oldResult = standing.results.find(result => result._id === _id);
              const newResult: Result = {
                _id: _id,
                driverId: standing.driver.startNumber,
                startEpc: gateReading.tag_code,
                finishEpc: gateReading.tag_code,
                microtime: diff,
                createdAt: finishTimestamp,
                correction: 0,
                verification: null
              };
              if (!oldResult) {
                standing.results.push(newResult);
                // console.log('Result #' + newResult._id + ' added.');
              } else if (!ResultsService.areDeepEqual(oldResult, newResult)) {
                standing.results[standing.results.indexOf(oldResult)] = {...oldResult, ...newResult};
                // console.log('Result #' + newResult._id + ' updated.');
              } else {
                // console.log('Result #' + newResult._id + ' not changed.');
              }
            }
            lastLapStartsByStartNumber[driver.startNumber] = gateReading;
          }
        } else if (standing) {
          lastLapStartsByStartNumber[driver.startNumber] = gateReading;
        }
      }
    }
    return Object.values<Standing>(standingsByStartNumber).sort(this.compareStandingsByEpc);
  }

  private compareStandingsByEpc(a: Standing, b: Standing): number {
    if (a.driver.epcs > b.driver.epcs) {
      return 1;
    } else if (a.driver.epcs < b.driver.epcs) {
      return -1;
    } else {
      return 0;
    }
  }

  private compareStandingsByStartNumber(a: Standing, b: Standing): number {
    return a.driver.startNumber - b.driver.startNumber;
  }
}
